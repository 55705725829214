<template>
  <v-layout
    column
    justify-center
    fill-height
    class="voting-mode"
    :class="{ 'voting-mode--host': isHost }"
  >
    <v-flex xs2 id="select-challenge-container" v-if="$_VotingMixin_isGameOver">
      <v-layout row justify-center>
        <rtb-select
          v-model="$_VotingMixin_currentLocalMissionID"
          :options="$_VotingMixin_missionList"
          size="sm"
          label="Mission"
          hide-label
          option-text="name"
          style="width: 160px"
        >
          <template #selected-option="{ item }">
            <svg-icon :name="item.icon" />
            {{ item.name }}
          </template>
          <template #option="{ icon, name }">
            <svg-icon :name="icon" />
            {{ name }}
          </template>
        </rtb-select>
        <button
          class="dowmload_mission_media download_all"
          @click="downloadMissonMedia"
        >
          <span class="contrast-text-color">
            <svg-icon name="cloud-download-regular" size="small" />
            {{ downloading ? "Processing..." : "Download" }}
          </span>
        </button>
      </v-layout>
    </v-flex>

    <v-flex shrink>
      <v-layout row justify-center>
        <v-flex class="voting-mode-wrap">
          <div
            v-if="isInstructionsVisible"
            class="voting-instructions voting-instructions-host"
            v-html="instructionsReal"
          />

          <v-btn
            v-if="isMakePollVisible"
            fab
            small
            active-class="grey darken-1"
            @click="makePoll()"
          >
            <v-icon> dns </v-icon>
          </v-btn>
          <!-- Controls -->

          <img
            v-if="currentPage > 0"
            class="prev-btn"
            @click="prevSlide"
            @mouseover="stopAutoPlay()"
            :src="require('@/assets/carousel-arrow-prev.png')"
          />
          <img
            v-if="currentPage < pageCount - 1"
            class="next-btn"
            @click="nextSlide"
            @mouseover="stopAutoPlay()"
            :src="require('@/assets/carousel-arrow-next.png')"
          />
          <Carousel
            :key="`${nOfVoteElements}-${$_VotingMixin_currentMissionID}`"
            :perPage="nOfSlidesPerPage"
            :paginationEnabled="false"
            :navigationEnabled="false"
            :loop="true"
            :autoplay="autoplay"
            :mouseDrag="false"
            :autoplayTimeout="3000"
            :style="{ maxWidth: carouselMaxWidth }"
            v-if="!!nOfVoteElements"
            class="carousel"
            ref="carousel"
            @pageChange="pageChange"
          >
            <Slide
              v-for="(media, idx) in $_VotingMixin_voteElementsPre"
              :key="'voting-element-' + idx + media.id"
            >
              <VotingCard
                class="voting-mode__card"
                :data="media"
                :isVideo="$_VotingMixin_isVideoBehavior"
                :isVideoControls="!$_VotingMixin_isVideoBehaviorEx"
                :mission="$_VotingMixin_mission"
                :ratio="$_VotingMixin_isDrawing ? '16:10' : '4:3'"
                :isGameOver="$_VotingMixin_isGameOver"
                @mouseover="stopAutoPlay()"
                @rated="$_VotingMixin_rated($event.rate, $event.data)"
              />
            </Slide>
          </Carousel>
          <button
            v-if="showDownloadMediaButton && !$_VotingMixin_isGameOver"
            class="dowmload_mission_media"
            @click="downloadMissonMedia"
          >
            <span class="contrast-text-color">
              <svg-icon name="cloud-download-regular" size="small" />
              {{ downloading ? "Processing..." : "Download" }}
            </span>
          </button>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { Carousel, Slide } from "vue-carousel"
import { findLast } from "lodash"

import MissionCollection from "@shared/MissionCollection"
import MissionType from "@shared/enums/Mission"

import { RtbSelect } from "@/components/ui"
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import UserName from "@/components/GroupTeams/Common/User/UserName.vue"
import { downloadMissonMedia } from "@/services/api.service"

import VotingMixin from "./VotingMixin"
import VotingCard from "./VotingCard.vue"

const CAROUSEL_OFFSET = 50

export default {
  name: "VotingMode",
  mixins: [VotingMixin],
  components: {
    VotingCard,
    RtbSelect,
    Carousel,
    Slide,
    RatioContainer,
    UserName
  },
  data() {
    return {
      autoplay: true,
      currentPage: 0,
      downloading: false,
      pollCreated: false,
      localMissionID: null,
      missions: {},
      votes: {}
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost", "token"]),
    isInstructionsVisible() {
      return (
        this.instructionsReal &&
        this.realCurrentMission &&
        [MissionType.Giphy, MissionType.FreeForm].includes(
          this.realCurrentMission.behavior
        )
      )
    },
    realCurrentMission() {
      return this.$store.getters.getCurrentMission
    },
    instructionsReal() {
      return this.realCurrentMission?.instructions?.replace("<br/>", "")
    },
    isMakePollVisible() {
      return (
        this.isHost &&
        this.$_VotingMixin_missionType === "Freeform" &&
        !this.pollCreated &&
        !this.$_VotingMixin_isGameOver
      )
    },
    vh() {
      return this.$_VotingMixin_isDrawing ? 58 : 45
    },
    carouselMaxWidth() {
      return `calc((${this.vh}vh - ${CAROUSEL_OFFSET}px) * ${this.nOfSlidesPerPage})`
    },
    showDownloadMediaButton() {
      return (
        this.$_VotingMixin_voteElementsPre.length &&
        ![
          MissionType.Giphy,
          MissionType.FreeForm,
          MissionType.UnconditionalPositiveRegard,
          MissionType.SpiralDrawing
        ].includes(this.$_VotingMixin_missionType)
      )
    },
    nOfVoteElements() {
      return this.$_VotingMixin_voteElementsPre.length
    },
    pageCount() {
      return this.nOfSlidesPerPage
        ? Math.ceil(
            this.$_VotingMixin_voteElementsPre.length / this.nOfSlidesPerPage
          )
        : 1
    },
    nOfSlidesPerPage() {
      // if at least one YouTube video found in the results
      // decrease the number of results per slide

      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1
        case "sm":
          return Math.min(
            this.$_VotingMixin_isDrawing ? 2 : 3,
            this.nOfVoteElements
          )
        case "md":
          return Math.min(
            this.$_VotingMixin_isDrawing ? 3 : 4,
            this.nOfVoteElements
          )
        case "lg":
          return Math.min(
            this.$_VotingMixin_isDrawing ? 3 : 5,
            this.nOfVoteElements
          )
        case "xl":
          return Math.min(
            this.$_VotingMixin_isDrawing ? 4 : 5,
            this.nOfVoteElements
          )
        default:
          return 1
      }
    }
  },

  async created() {
    this.$_VotingMixin_fetchMissions()
  },
  watch: {
    $_VotingMixin_currentMissionID(value) {
      if (value && this.$refs.carousel) {
        this.currentPage = 0
        this.$refs.carousel.goToPage(0)
      }
    }
  },

  methods: {
    stopAutoPlay() {
      if (this.autoplay) {
        this.autoplay = false
      }
    },

    makePoll() {
      var topVotes = this.$_VotingMixin_voteElementsPre.filter(
        vote => vote.score
      )

      topVotes = topVotes.sort((a, b) => b.score - a.score)

      if (topVotes.length > 4) {
        topVotes = topVotes.splice(0, 5)
      }

      const answers = topVotes.reduce(
        (acc, { correct, firstanme, lastname }, index) => {
          const answer = `${correct} -- ${firstanme} ${lastname.substring(
            0,
            1
          )}`
          acc[`ansver${index + 1}`] = answer
          return acc
        },
        {}
      )

      const { activityId } = this.$_VotingMixin_mission

      const lastStepPos = findLast(
        MissionCollection.normalize(this.$store.getters.missions),
        mission => mission.activityId === activityId
      ).pos

      const index = activityId ? lastStepPos : this.$_VotingMixin_mission.pos

      this.pollCreated = true

      this.$store.dispatch("addToMissions", {
        array: [
          {
            title: "Poll",
            name: "Added Poll for " + this.$_VotingMixin_mission.name,
            points: 100,
            playType: "Individual: Speed Does Not Matter",
            numOfTries: "Unlimited",
            behavior: "Poll",
            answer: "",
            gameID: this.$_VotingMixin_gameID,
            pos: index + 0.5,
            orgID: this.$_VotingMixin_orgID,
            time: 30,
            instructions:
              this.$_VotingMixin_mission.instructions ||
              this.$_VotingMixin_playStep ||
              "",
            play: true,
            results: true,
            ...answers
          }
        ],
        index
      })
    },
    async downloadMissonMedia() {
      this.downloading = true
      const response = await downloadMissonMedia({
        orgID: this.$_VotingMixin_mission.orgID,
        gameID: this.$_VotingMixin_gameID,
        missionId:
          !this.$_VotingMixin_isGameOver && this.$_VotingMixin_currentMissionID,
        token: this.token
      }).finally(() => {
        this.downloading = false
      })

      if (response.status !== "success") {
        throw new Error(response.message)
      }

      const a = document.createElement("a")
      a.setAttribute("href", response.data.url)
      a.setAttribute("target", "_blank")
      a.click()
    },
    pageChange(n) {
      this.currentPage = n
    },
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage())
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage())
    }
  }
}
</script>

<style lang="scss">
.voting-mode {
  $block: &;
  font-size: 1em;
  line-height: 1;

  &__card {
    margin-left: 8px;
    margin-right: 8px;
    max-width: calc(58vh - 80px) !important;

    #{$block}--host & {
      max-width: calc(58vh - 240px) !important;
    }
  }

  .voting-instructions {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    background: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    );
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 20px;
    font-family: Sofia Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 20px;
    color: #f8f9fb;
    padding: 17px 15px 20px;
    margin-bottom: 15px;
    margin-top: -10px;

    p {
      line-height: 1.5;
      margin: 0;
    }
  }

  .voting-instructions-host {
    font-weight: normal !important;
    font-size: 18px !important;
    line-height: 18px !important;
    padding: 5px 15px !important;
  }

  .voting-mode-wrap {
    width: 100%;
    margin: auto;
  }

  .next-btn,
  .prev-btn {
    position: absolute;
    top: 45%;
    height: 50px;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;
    &:hover {
      transform: translateY(-50%) scale(0.95);
    }
  }

  .prev-btn {
    left: 1% !important;
  }

  .next-btn {
    right: 1% !important;
  }

  .carousel {
    margin: 0 auto;
    box-sizing: content-box;
  }

  .dowmload_mission_media {
    display: inline-block;
    margin-left: 16px;
    padding: 5px 10px;
    border-radius: 15px;
    color: $primary_accent_color;
    background: currentColor;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    transition: opacity 0.5s ease-out;
    white-space: nowrap;
    cursor: pointer;

    &:not(.download_all) {
      margin-top: 20px;
      margin-left: 0;
    }

    &:hover {
      filter: brightness(1.1);
    }

    .svg-icon {
      vertical-align: text-bottom;
    }
  }
}
</style>
