var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "voting-mode",
      class: { "voting-mode--host": _vm.isHost },
      attrs: { column: "", "justify-center": "", "fill-height": "" },
    },
    [
      _vm.$_VotingMixin_isGameOver
        ? _c(
            "v-flex",
            { attrs: { xs2: "", id: "select-challenge-container" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", "justify-center": "" } },
                [
                  _c("rtb-select", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      options: _vm.$_VotingMixin_missionList,
                      size: "sm",
                      label: "Mission",
                      "hide-label": "",
                      "option-text": "name",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selected-option",
                          fn: function (ref) {
                            var item = ref.item
                            return [
                              _c("svg-icon", { attrs: { name: item.icon } }),
                              _vm._v(" " + _vm._s(item.name) + " "),
                            ]
                          },
                        },
                        {
                          key: "option",
                          fn: function (ref) {
                            var icon = ref.icon
                            var name = ref.name
                            return [
                              _c("svg-icon", { attrs: { name: icon } }),
                              _vm._v(" " + _vm._s(name) + " "),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3821786840
                    ),
                    model: {
                      value: _vm.$_VotingMixin_currentLocalMissionID,
                      callback: function ($$v) {
                        _vm.$_VotingMixin_currentLocalMissionID = $$v
                      },
                      expression: "$_VotingMixin_currentLocalMissionID",
                    },
                  }),
                  _c(
                    "button",
                    {
                      staticClass: "dowmload_mission_media download_all",
                      on: { click: _vm.downloadMissonMedia },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "contrast-text-color" },
                        [
                          _c("svg-icon", {
                            attrs: {
                              name: "cloud-download-regular",
                              size: "small",
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.downloading ? "Processing..." : "Download"
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "" } },
            [
              _c(
                "v-flex",
                { staticClass: "voting-mode-wrap" },
                [
                  _vm.isInstructionsVisible
                    ? _c("div", {
                        staticClass:
                          "voting-instructions voting-instructions-host",
                        domProps: { innerHTML: _vm._s(_vm.instructionsReal) },
                      })
                    : _vm._e(),
                  _vm.isMakePollVisible
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            small: "",
                            "active-class": "grey darken-1",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.makePoll()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(" dns ")])],
                        1
                      )
                    : _vm._e(),
                  _vm.currentPage > 0
                    ? _c("img", {
                        staticClass: "prev-btn",
                        attrs: {
                          src: require("@/assets/carousel-arrow-prev.png"),
                        },
                        on: {
                          click: _vm.prevSlide,
                          mouseover: function ($event) {
                            return _vm.stopAutoPlay()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.currentPage < _vm.pageCount - 1
                    ? _c("img", {
                        staticClass: "next-btn",
                        attrs: {
                          src: require("@/assets/carousel-arrow-next.png"),
                        },
                        on: {
                          click: _vm.nextSlide,
                          mouseover: function ($event) {
                            return _vm.stopAutoPlay()
                          },
                        },
                      })
                    : _vm._e(),
                  !!_vm.nOfVoteElements
                    ? _c(
                        "Carousel",
                        {
                          key:
                            _vm.nOfVoteElements +
                            "-" +
                            _vm.$_VotingMixin_currentMissionID,
                          ref: "carousel",
                          staticClass: "carousel",
                          style: { maxWidth: _vm.carouselMaxWidth },
                          attrs: {
                            perPage: _vm.nOfSlidesPerPage,
                            paginationEnabled: false,
                            navigationEnabled: false,
                            loop: true,
                            autoplay: _vm.autoplay,
                            mouseDrag: false,
                            autoplayTimeout: 3000,
                          },
                          on: { pageChange: _vm.pageChange },
                        },
                        _vm._l(
                          _vm.$_VotingMixin_voteElementsPre,
                          function (media, idx) {
                            return _c(
                              "Slide",
                              { key: "voting-element-" + idx + media.id },
                              [
                                _c("VotingCard", {
                                  staticClass: "voting-mode__card",
                                  attrs: {
                                    data: media,
                                    isVideo: _vm.$_VotingMixin_isVideoBehavior,
                                    isVideoControls:
                                      !_vm.$_VotingMixin_isVideoBehaviorEx,
                                    mission: _vm.$_VotingMixin_mission,
                                    ratio: _vm.$_VotingMixin_isDrawing
                                      ? "16:10"
                                      : "4:3",
                                    isGameOver: _vm.$_VotingMixin_isGameOver,
                                  },
                                  on: {
                                    mouseover: function ($event) {
                                      return _vm.stopAutoPlay()
                                    },
                                    rated: function ($event) {
                                      return _vm.$_VotingMixin_rated(
                                        $event.rate,
                                        $event.data
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm.showDownloadMediaButton && !_vm.$_VotingMixin_isGameOver
                    ? _c(
                        "button",
                        {
                          staticClass: "dowmload_mission_media",
                          on: { click: _vm.downloadMissonMedia },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "contrast-text-color" },
                            [
                              _c("svg-icon", {
                                attrs: {
                                  name: "cloud-download-regular",
                                  size: "small",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.downloading
                                      ? "Processing..."
                                      : "Download"
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }